var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c('div', {
    staticClass: "content-right-wrapper"
  }, [_vm._m(0), _c('div', {
    staticClass: "plan-form"
  }, [_vm.planInfo.planId ? _c('div', {
    staticClass: "field"
  }, [_c('label', [_vm._v("计划本单号")]), _c('input', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.planInfo.orderNo,
      expression: "planInfo.orderNo"
    }],
    attrs: {
      "type": "text"
    },
    domProps: {
      "value": _vm.planInfo.orderNo
    },
    on: {
      "input": function input($event) {
        if ($event.target.composing) return;
        _vm.$set(_vm.planInfo, "orderNo", $event.target.value);
      }
    }
  })]) : _vm._e(), _vm.planInfo.planId ? _c('div', {
    staticClass: "field"
  }, [_c('label', [_vm._v("上报时间")]), _c('input', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.planInfo.createTime,
      expression: "planInfo.createTime"
    }],
    attrs: {
      "type": "text"
    },
    domProps: {
      "value": _vm.planInfo.createTime
    },
    on: {
      "input": function input($event) {
        if ($event.target.composing) return;
        _vm.$set(_vm.planInfo, "createTime", $event.target.value);
      }
    }
  })]) : _vm._e(), _vm.planInfo.planId ? _c('div', {
    staticClass: "field"
  }, [_c('label', [_vm._v("处理时间")]), _c('input', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.planInfo.dealTime,
      expression: "planInfo.dealTime"
    }],
    attrs: {
      "type": "text"
    },
    domProps: {
      "value": _vm.planInfo.dealTime
    },
    on: {
      "input": function input($event) {
        if ($event.target.composing) return;
        _vm.$set(_vm.planInfo, "dealTime", $event.target.value);
      }
    }
  })]) : _vm._e(), _c('div', {
    staticClass: "field"
  }, [_c('label', [_vm._v("图片计划")]), _c('div', {
    staticClass: "img-content-cls"
  }, [_vm._l(_vm.planInfo.imageList, function (item, i) {
    return _c('div', {
      key: i,
      staticClass: "image-big-box"
    }, [_c('img', {
      staticStyle: {
        "width": "auto",
        "height": "auto",
        "max-width": "100%",
        "max-height": "100%"
      },
      attrs: {
        "src": item
      },
      on: {
        "click": function click($event) {
          return _vm.previewImg(i);
        }
      }
    }), _vm.planInfo.status === 1 ? _c('div', {
      staticClass: "delIcon",
      on: {
        "click": function click($event) {
          return _vm.delImg(i);
        }
      }
    }, [_c('i', {
      staticClass: "iconfont icon-close"
    })]) : _vm._e()]);
  }), (!_vm.planInfo.imageList || _vm.planInfo.imageList.length < 10) && (!_vm.planInfo.status || _vm.planInfo.status === 1) ? _c('div', {
    staticStyle: {
      "display": "inline-block",
      "margin": "5px 10px"
    }
  }, [_c('img', {
    staticStyle: {
      "width": "70px",
      "height": "70px"
    },
    attrs: {
      "src": require("@/assets-v2/images/add.png")
    },
    on: {
      "click": _vm.clickImgUpload
    }
  }), _c('input', {
    ref: "imgUploadInput",
    staticStyle: {
      "display": "none"
    },
    attrs: {
      "type": "file",
      "accept": "image/*"
    },
    on: {
      "change": function change($event) {
        return _vm.uploadImg($event);
      }
    }
  })]) : _vm._e()], 2)]), _c('div', {
    staticClass: "field"
  }, [_c('label', [_vm._v("表格计划")]), _vm._l(_vm.planInfo.fileList, function (item, i) {
    return _c('div', {
      key: i,
      staticClass: "file-big-box"
    }, [_c('div', {
      staticClass: "uploaded-file-display"
    }, [_c('div', {
      staticClass: "file-name",
      on: {
        "click": function click($event) {
          return _vm.previewFile(item.fileUrl);
        }
      }
    }, [_vm._v(_vm._s(item.fileName))]), _vm.planInfo.status === 1 ? _c('div', {
      staticClass: "del-operation",
      on: {
        "click": function click($event) {
          return _vm.delFile(i);
        }
      }
    }, [_c('i', {
      staticClass: "iconfont icon-close"
    })]) : _vm._e()])]);
  }), !_vm.planInfo.status || _vm.planInfo.status === 1 ? _c('div', {
    staticStyle: {
      "display": "inline-block",
      "margin": "5px 10px"
    }
  }, [_c('img', {
    staticStyle: {
      "width": "70px",
      "height": "70px"
    },
    attrs: {
      "src": require("@/assets-v2/images/add.png")
    },
    on: {
      "click": _vm.clickFileUpload
    }
  }), _c('input', {
    ref: "fileUploadInput",
    staticStyle: {
      "display": "none"
    },
    attrs: {
      "type": "file",
      "accept": ".xlsx,.xls"
    },
    on: {
      "change": function change($event) {
        return _vm.uploadFile($event);
      }
    }
  })]) : _vm._e()], 2), _c('div', {
    staticClass: "field"
  }, [_c('label', [_vm._v("计划备注")]), _c('textarea', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.planInfo.remarks,
      expression: "planInfo.remarks"
    }],
    staticStyle: {
      "width": "500px",
      "border": "1px solid #ededed"
    },
    attrs: {
      "disabled": _vm.planInfo.status && _vm.planInfo.status > 1,
      "rows": "4"
    },
    domProps: {
      "value": _vm.planInfo.remarks
    },
    on: {
      "input": function input($event) {
        if ($event.target.composing) return;
        _vm.$set(_vm.planInfo, "remarks", $event.target.value);
      }
    }
  })]), _vm.planInfo.planId ? _c('div', {
    staticClass: "field"
  }, [_c('label', [_vm._v("处理结果")]), _c('textarea', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.planInfo.dealResult,
      expression: "planInfo.dealResult"
    }],
    staticStyle: {
      "width": "500px",
      "border": "1px solid #ededed"
    },
    attrs: {
      "rows": "4"
    },
    domProps: {
      "value": _vm.planInfo.dealResult
    },
    on: {
      "input": function input($event) {
        if ($event.target.composing) return;
        _vm.$set(_vm.planInfo, "dealResult", $event.target.value);
      }
    }
  })]) : _vm._e(), _c('div', {
    staticClass: "btn"
  }, [_c('button', {
    staticClass: "btn-cls",
    attrs: {
      "type": "button"
    },
    on: {
      "click": _vm.back
    }
  }, [_vm._v("返回")]), !_vm.planInfo.status || _vm.planInfo.status === 1 ? _c('button', {
    staticClass: "btn-cls",
    attrs: {
      "type": "button"
    },
    on: {
      "click": _vm.preSubmit
    }
  }, [_vm._v("提交")]) : _vm._e()])]), _vm.previewImgShow ? _c('div', {
    staticClass: "msg-popup"
  }, [_c('div', {
    staticClass: "msg-popup-mask",
    on: {
      "click": function click($event) {
        $event.stopPropagation();
        return _vm.closePreviewImgShow.apply(null, arguments);
      }
    }
  }), _c('div', {
    staticClass: "popup_content"
  }, [_c('img-preview', {
    attrs: {
      "previewImg": _vm.previewImgInfo
    },
    on: {
      "close": _vm.closePreviewImgShow
    }
  })], 1)]) : _vm._e(), _vm.previewFileShow ? _c('div', {
    staticClass: "msg-popup"
  }, [_c('div', {
    staticClass: "msg-popup-mask",
    on: {
      "click": function click($event) {
        $event.stopPropagation();
        return _vm.closePreviewFileShow.apply(null, arguments);
      }
    }
  }), _c('div', {
    staticClass: "popup_content"
  }, [_c('div', {
    staticClass: "popup_close",
    on: {
      "click": function click($event) {
        $event.stopPropagation();
        return _vm.closePreviewFileShow.apply(null, arguments);
      }
    }
  }, [_vm._v("X")]), _c('div', {
    staticClass: "popup_download_btn",
    on: {
      "click": function click($event) {
        $event.stopPropagation();
        return _vm.downLoadPreviewFile.apply(null, arguments);
      }
    }
  }, [_vm._v("下载")]), _c('iframe', {
    attrs: {
      "src": _vm.previewFileUrl,
      "width": "100%",
      "height": "100%"
    }
  })])]) : _vm._e()]);
};
var staticRenderFns = [function () {
  var _vm = this,
    _c = _vm._self._c;
  return _c('div', {
    staticClass: "right-title"
  }, [_c('span', [_vm._v("新增计划本")])]);
}];
export { render, staticRenderFns };