import "core-js/modules/es.array.concat.js";
export default {
  name: 'img-preview',
  props: {
    previewImg: {
      type: Object,
      default: function _default() {}
    }
  },
  data: function data() {
    return {
      transform: {
        scale: 1,
        degree: 0
      }
    };
  },
  computed: {
    isSingle: function isSingle() {
      return this.previewImg.imgList.length <= 1;
    },
    isFirst: function isFirst() {
      return this.previewImg.index === 0;
    },
    isLast: function isLast() {
      return this.previewImg.index === this.previewImg.imgList.length - 1;
    },
    imgStyle: function imgStyle() {
      var _this$transform = this.transform,
        scale = _this$transform.scale,
        degree = _this$transform.degree;
      var style = {
        transform: "scale(".concat(scale, ") rotate(").concat(degree, "deg)")
      };
      return style;
    }
  },
  methods: {
    prev: function prev() {
      if (this.isFirst && !this.previewImg.infinite) return;
      if (this.$parent.previewImgInfo.index >= 0) {
        var len = this.previewImg.imgList.length;
        this.$parent.previewImgInfo.index = (this.$parent.previewImgInfo.index - 1 + len) % len;
        this.reset();
      }
    },
    next: function next() {
      if (this.isLast && !this.previewImg.infinite) return;
      if (this.previewImg.imgList.length > this.$parent.previewImgInfo.index) {
        var len = this.previewImg.imgList.length;
        this.$parent.previewImgInfo.index = (this.$parent.previewImgInfo.index + 1) % len;
        this.reset();
      }
    },
    reset: function reset() {
      this.transform = {
        scale: 1,
        degree: 0
      };
    },
    showCurrent: function showCurrent(index) {
      this.previewImg.index = index;
    },
    close: function close() {
      this.$parent.previewImgInfo.popup = false;
      this.$emit('close');
    }
  }
};