var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _vm.previewImg.popup ? _c('div', {
    staticClass: "rlPopup"
  }, [_c('div', {
    staticClass: "close-big-img",
    on: {
      "click": function click($event) {
        return _vm.close();
      }
    }
  }, [_vm._v(" X ")]), !_vm.isSingle ? _c('div', {
    staticClass: "prev handle-btn",
    on: {
      "click": function click($event) {
        return _vm.prev();
      }
    }
  }, [_vm._v(" < ")]) : _vm._e(), !_vm.isSingle ? _c('div', {
    staticClass: "next handle-btn",
    on: {
      "click": function click($event) {
        return _vm.next();
      }
    }
  }, [_vm._v(" > ")]) : _vm._e(), _c('div', {
    staticClass: "thumb-wrap"
  }, [_c('ul', _vm._l(_vm.previewImg.imgList, function (img, index) {
    return _c('li', {
      key: img,
      class: {
        active: _vm.previewImg.index === index
      },
      on: {
        "click": function click($event) {
          return _vm.showCurrent(index);
        }
      }
    }, [_c('img', {
      attrs: {
        "src": img
      }
    })]);
  }), 0)]), _c('div', {
    staticClass: "img-list-wrap"
  }, _vm._l(_vm.previewImg.imgList, function (img, i) {
    return _c('div', {
      key: img
    }, [i === _vm.previewImg.index ? _c('img', {
      ref: "img",
      refInFor: true,
      style: _vm.imgStyle,
      attrs: {
        "src": img
      }
    }) : _vm._e()]);
  }), 0)]) : _vm._e();
};
var staticRenderFns = [];
export { render, staticRenderFns };